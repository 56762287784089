<template>
  <div>
    <section class="technical-drawing__section th-hero-slider">
      <div class="th-hero-slider__inner">
        <div class="swiper-container __js_th-hero-slider">
          <div class="swiper-wrapper">
            <div class="th-hero-slider__slide swiper-slide">
              <div class="th-hero-slider__content">
                <div class="th-hero-slider__heading">Cruz Roja Mexicana
                  <br>Delegación Chihuahua
                </div>
                <div class="th-hero-slider__text">Todos los días la Cruz Roja Mexicana es protagonista de una historia en la que la vida triunfa.</div>
                <router-link
                  class="th-hero-slider__btn btn btn--technical"
                  to="/unidades-medicas"
                >encuentra tu unidad médica más cercana</router-link>
              </div>
              <div class="th-hero-slider__image">
                <img
                  src="@/assets/img/home.jpg"
                  alt=""
                >
                <div class="th-hero-slider__circle">
                  <img
                    src="@/assets/logo.svg"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <citas-aviso />
    <section class="technical-drawing__section th-our-story">
      <div class="th-our-story__inner">
        <div class="th-our-story__exp">
          <div class="th-our-story__exp-title">voluntarios que hacen esto posible</div>
          <div class="th-our-story__exp-value">168</div>
        </div>
        <div class="th-our-story__content">
          <div class="th-our-story__text">Una de las razones por las que la Cruz Roja se ha convertido en la orgazación que somos es debido a que conectamos con la gente y las comunidades en todo el país y en todo el mundo.</div>
          <div class="th-our-story__buttons">
            <router-link
              class="th-our-story__btn btn btn--technical"
              to="/sobre-nosotros"
            >conoce más sobre nosotros</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CitasAviso from "@/components/CitasAviso.vue";
export default {
  name: "Home",
  components: { CitasAviso },
};
</script>
